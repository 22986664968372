import React from 'react';

const NewPermission = () => {
    return (
        <div>
            <h1>This is add new permissions</h1>
        </div>
    );
}

export default NewPermission;
