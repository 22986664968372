const orders = [
    {
      id: '001',
      fullname: 'John Doe',
      address: '123 Main St',
      phone: '555-1234',
      date: '2024-08-15',
      status: 'PENDING',
      manual: true,
      products: [
        { name: 'Product 1', color: 'Red', size: 'M', quantity: 2, unitPrice: 20 },
        { name: 'Product 2', color: 'Blue', size: 'L', quantity: 1, unitPrice: 30 },
      ],
      shipping: 5,
      discount: 0,
      total: 75,
    },
    {
      id: '002',
      fullname: 'Jane Smith',
      address: '456 Elm St',
      phone: '555-5678',
      date: '2024-08-12',
      status: 'CONFIRMING',
      manual: false,
      products: [
        { name: 'Product 3', color: 'Green', size: 'S', quantity: 3, unitPrice: 15 },
        { name: 'Product 4', color: 'Red', size: 'M', quantity: 1, unitPrice: 25 },
      ],
      shipping: 10,
      discount: 5,
      total: 85,
    },
    {
      id: '003',
      fullname: 'Alice Brown',
      address: '789 Oak St',
      phone: '555-9012',
      date: '2024-08-10',
      status: 'CONFIRMED',
      manual: true,
      products: [
        { name: 'Product 5', color: 'Blue', size: 'L', quantity: 2, unitPrice: 40 },
        { name: 'Product 6', color: 'Green', size: 'S', quantity: 1, unitPrice: 35 },
      ],
      shipping: 7,
      discount: 10,
      total: 112,
    },
    {
      id: '004',
      fullname: 'Bob White',
      address: '101 Pine St',
      phone: '555-2345',
      date: '2024-08-18',
      status: 'IN PROCESS',
      manual: false,
      products: [
        { name: 'Product 7', color: 'Red', size: 'M', quantity: 1, unitPrice: 50 },
        { name: 'Product 8', color: 'Blue', size: 'L', quantity: 2, unitPrice: 20 },
      ],
      shipping: 8,
      discount: 0,
      total: 98,
    },
    {
      id: '005',
      fullname: 'Charlie Green',
      address: '202 Maple St',
      phone: '555-6789',
      date: '2024-08-05',
      status: 'ON DELIVERY',
      manual: true,
      products: [
        { name: 'Product 9', color: 'Green', size: 'S', quantity: 1, unitPrice: 60 },
        { name: 'Product 10', color: 'Red', size: 'M', quantity: 1, unitPrice: 45 },
      ],
      shipping: 9,
      discount: 5,
      total: 109,
    },
    {
      id: '006',
      fullname: 'David Blue',
      address: '303 Cedar St',
      phone: '555-3456',
      date: '2024-08-03',
      status: 'DELIVERED',
      manual: false,
      products: [
        { name: 'Product 11', color: 'Blue', size: 'L', quantity: 1, unitPrice: 55 },
        { name: 'Product 12', color: 'Green', size: 'S', quantity: 2, unitPrice: 25 },
      ],
      shipping: 6,
      discount: 0,
      total: 111,
    },
    {
      id: '007',
      fullname: 'Eve Black',
      address: '404 Birch St',
      phone: '555-7890',
      date: '2024-08-08',
      status: 'PENDING',
      manual: true,
      products: [
        { name: 'Product 13', color: 'Red', size: 'M', quantity: 3, unitPrice: 30 },
        { name: 'Product 14', color: 'Blue', size: 'L', quantity: 1, unitPrice: 40 },
      ],
      shipping: 7,
      discount: 0,
      total: 137,
    },
    {
      id: '008',
      fullname: 'Frank Yellow',
      address: '505 Spruce St',
      phone: '555-0123',
      date: '2024-08-20',
      status: 'CONFIRMING',
      manual: false,
      products: [
        { name: 'Product 15', color: 'Green', size: 'S', quantity: 2, unitPrice: 35 },
        { name: 'Product 16', color: 'Red', size: 'M', quantity: 1, unitPrice: 50 },
      ],
      shipping: 5,
      discount: 0,
      total: 125,
    },
    {
      id: '009',
      fullname: 'Grace White',
      address: '606 Redwood St',
      phone: '555-3456',
      date: '2024-08-22',
      status: 'CONFIRMED',
      manual: true,
      products: [
        { name: 'Product 17', color: 'Blue', size: 'L', quantity: 2, unitPrice: 25 },
        { name: 'Product 18', color: 'Green', size: 'S', quantity: 3, unitPrice: 20 },
      ],
      shipping: 10,
      discount: 0,
      total: 130,
    },
    {
      id: '010',
      fullname: 'Henry Red',
      address: '707 Chestnut St',
      phone: '555-7890',
      date: '2024-08-11',
      status: 'IN PROCESS',
      manual: false,
      products: [
        { name: 'Product 19', color: 'Red', size: 'M', quantity: 2, unitPrice: 45 },
        { name: 'Product 20', color: 'Blue', size: 'L', quantity: 1, unitPrice: 55 },
      ],
      shipping: 12,
      discount: 0,
      total: 157,
    },
    {
      id: '011',
      fullname: 'Isabel Green',
      address: '808 Palm St',
      phone: '555-5678',
      date: '2024-08-13',
      status: 'ON DELIVERY',
      manual: true,
      products: [
        { name: 'Product 21', color: 'Green', size: 'S', quantity: 1, unitPrice: 65 },
        { name: 'Product 22', color: 'Red', size: 'M', quantity: 1, unitPrice: 75 },
      ],
      shipping: 10,
      discount: 15,
      total: 135,
    },
    {
      id: '012',
      fullname: 'Jack Brown',
      address: '909 Fir St',
      phone: '555-2345',
      date: '2024-08-14',
      status: 'DELIVERED',
      manual: false,
      products: [
        { name: 'Product 23', color: 'Blue', size: 'L', quantity: 3, unitPrice: 30 },
        { name: 'Product 24', color: 'Green', size: 'S', quantity: 2, unitPrice: 25 },
      ],
      shipping: 8,
      discount: 10,
      total: 148,
    },
    {
      id: '013',
      fullname: 'Karen Gray',
      address: '1010 Pine St',
      phone: '555-1234',
      date: '2024-08-01',
      status: 'PENDING',
      manual: true,
      products: [
        { name: 'Product 25', color: 'Red', size: 'M', quantity: 1, unitPrice: 50 },
        { name: 'Product 26', color: 'Blue', size: 'L', quantity: 2, unitPrice: 40 },
      ],
      shipping: 9,
      discount: 5,
      total: 134,
    },
    {
      id: '014',
      fullname: 'Laura Green',
      address: '1111 Oak St',
      phone: '555-5678',
      date: '2024-08-06',
      status: 'CONFIRMING',
      manual: false,
      products: [
        { name: 'Product 27', color: 'Green', size: 'S', quantity: 3, unitPrice: 30 },
        { name: 'Product 28', color: 'Red', size: 'M', quantity: 1, unitPrice: 45 },
      ],
      shipping: 6,
      discount: 10,
      total: 131,
    },
    {
      id: '015',
      fullname: 'Michael Blue',
      address: '1212 Elm St',
      phone: '555-7890',
      date: '2024-08-04',
      status: 'CONFIRMED',
      manual: true,
      products: [
        { name: 'Product 29', color: 'Blue', size: 'L', quantity: 2, unitPrice: 35 },
        { name: 'Product 30', color: 'Green', size: 'S', quantity: 1, unitPrice: 55 },
      ],
      shipping: 7,
      discount: 5,
      total: 137,
    },
    {
      id: '016',
      fullname: 'Nancy Brown',
      address: '1313 Maple St',
      phone: '555-2345',
      date: '2024-08-17',
      status: 'IN PROCESS',
      manual: false,
      products: [
        { name: 'Product 31', color: 'Red', size: 'M', quantity: 3, unitPrice: 25 },
        { name: 'Product 32', color: 'Blue', size: 'L', quantity: 2, unitPrice: 40 },
      ],
      shipping: 8,
      discount: 0,
      total: 173,
    },
    {
      id: '017',
      fullname: 'Olivia Green',
      address: '1414 Cedar St',
      phone: '555-5678',
      date: '2024-08-19',
      status: 'ON DELIVERY',
      manual: true,
      products: [
        { name: 'Product 33', color: 'Green', size: 'S', quantity: 2, unitPrice: 60 },
        { name: 'Product 34', color: 'Red', size: 'M', quantity: 1, unitPrice: 50 },
      ],
      shipping: 6,
      discount: 0,
      total: 176,
    },
    {
      id: '018',
      fullname: 'Peter White',
      address: '1515 Birch St',
      phone: '555-3456',
      date: '2024-08-02',
      status: 'DELIVERED',
      manual: false,
      products: [
        { name: 'Product 35', color: 'Blue', size: 'L', quantity: 1, unitPrice: 70 },
        { name: 'Product 36', color: 'Green', size: 'S', quantity: 1, unitPrice: 45 },
      ],
      shipping: 10,
      discount: 10,
      total: 115,
    },
    {
      id: '019',
      fullname: 'Quincy Yellow',
      address: '1616 Redwood St',
      phone: '555-7890',
      date: '2024-08-21',
      status: 'PENDING',
      manual: true,
      products: [
        { name: 'Product 37', color: 'Red', size: 'M', quantity: 2, unitPrice: 35 },
        { name: 'Product 38', color: 'Blue', size: 'L', quantity: 3, unitPrice: 25 },
      ],
      shipping: 5,
      discount: 5,
      total: 160,
    },
    {
      id: '020',
      fullname: 'Rachel Black',
      address: '1717 Palm St',
      phone: '555-0123',
      date: '2024-08-07',
      status: 'CONFIRMING',
      manual: false,
      products: [
        { name: 'Product 39', color: 'Green', size: 'S', quantity: 1, unitPrice: 75 },
        { name: 'Product 40', color: 'Red', size: 'M', quantity: 1, unitPrice: 55 },
      ],
      shipping: 12,
      discount: 10,
      total: 132,
    },
    {
      id: '021',
      fullname: 'Sam Red',
      address: '1818 Fir St',
      phone: '555-3456',
      date: '2024-08-09',
      status: 'CONFIRMED',
      manual: true,
      products: [
        { name: 'Product 41', color: 'Blue', size: 'L', quantity: 2, unitPrice: 40 },
        { name: 'Product 42', color: 'Green', size: 'S', quantity: 1, unitPrice: 50 },
      ],
      shipping: 7,
      discount: 0,
      total: 137,
    },
    {
      id: '022',
      fullname: 'Tina White',
      address: '1919 Pine St',
      phone: '555-7890',
      date: '2024-08-03',
      status: 'IN PROCESS',
      manual: false,
      products: [
        { name: 'Product 43', color: 'Red', size: 'M', quantity: 1, unitPrice: 60 },
        { name: 'Product 44', color: 'Blue', size: 'L', quantity: 2, unitPrice: 30 },
      ],
      shipping: 6,
      discount: 5,
      total: 151,
    },
    {
      id: '023',
      fullname: 'Uma Brown',
      address: '2020 Oak St',
      phone: '555-5678',
      date: '2024-08-16',
      status: 'ON DELIVERY',
      manual: true,
      products: [
        { name: 'Product 45', color: 'Green', size: 'S', quantity: 2, unitPrice: 35 },
        { name: 'Product 46', color: 'Red', size: 'M', quantity: 1, unitPrice: 45 },
      ],
      shipping: 8,
      discount: 0,
      total: 123,
    },
    {
      id: '024',
      fullname: 'Victor Blue',
      address: '2121 Elm St',
      phone: '555-1234',
      date: '2024-08-05',
      status: 'DELIVERED',
      manual: false,
      products: [
        { name: 'Product 47', color: 'Blue', size: 'L', quantity: 1, unitPrice: 75 },
        { name: 'Product 48', color: 'Green', size: 'S', quantity: 2, unitPrice: 40 },
      ],
      shipping: 10,
      discount: 0,
      total: 165,
    },
    {
      id: '025',
      fullname: 'Wendy Green',
      address: '2222 Cedar St',
      phone: '555-2345',
      date: '2024-08-12',
      status: 'PENDING',
      manual: true,
      products: [
        { name: 'Product 49', color: 'Red', size: 'M', quantity: 3, unitPrice: 30 },
        { name: 'Product 50', color: 'Blue', size: 'L', quantity: 2, unitPrice: 45 },
      ],
      shipping: 7,
      discount: 5,
      total: 182,
    },
    {
      id: '026',
      fullname: 'Xander White',
      address: '2323 Maple St',
      phone: '555-5678',
      date: '2024-08-14',
      status: 'CONFIRMING',
      manual: false,
      products: [
        { name: 'Product 51', color: 'Green', size: 'S', quantity: 2, unitPrice: 55 },
        { name: 'Product 52', color: 'Red', size: 'M', quantity: 1, unitPrice: 60 },
      ],
      shipping: 6,
      discount: 10,
      total: 151,
    },
    {
      id: '027',
      fullname: 'Yara Black',
      address: '2424 Birch St',
      phone: '555-7890',
      date: '2024-08-18',
      status: 'CONFIRMED',
      manual: true,
      products: [
        { name: 'Product 53', color: 'Blue', size: 'L', quantity: 1, unitPrice: 80 },
        { name: 'Product 54', color: 'Green', size: 'S', quantity: 3, unitPrice: 35 },
      ],
      shipping: 8,
      discount: 0,
      total: 193,
    },
    {
      id: '028',
      fullname: 'Zane Yellow',
      address: '2525 Redwood St',
      phone: '555-3456',
      date: '2024-08-20',
      status: 'IN PROCESS',
      manual: false,
      products: [
        { name: 'Product 55', color: 'Red', size: 'M', quantity: 2, unitPrice: 65 },
        { name: 'Product 56', color: 'Blue', size: 'L', quantity: 1, unitPrice: 70 },
      ],
      shipping: 5,
      discount: 15,
      total: 190,
    },
    {
      id: '029',
      fullname: 'Ava White',
      address: '2626 Pine St',
      phone: '555-0123',
      date: '2024-08-22',
      status: 'ON DELIVERY',
      manual: true,
      products: [
        { name: 'Product 57', color: 'Green', size: 'S', quantity: 2, unitPrice: 50 },
        { name: 'Product 58', color: 'Red', size: 'M', quantity: 1, unitPrice: 75 },
      ],
      shipping: 10,
      discount: 5,
      total: 180,
    },
    {
      id: '030',
      fullname: 'Bella Brown',
      address: '2727 Fir St',
      phone: '555-3456',
      date: '2024-08-19',
      status: 'DELIVERED',
      manual: false,
      products: [
        { name: 'Product 59', color: 'Blue', size: 'L', quantity: 1, unitPrice: 85 },
        { name: 'Product 60', color: 'Green', size: 'S', quantity: 1, unitPrice: 50 },
      ],
      shipping: 7,
      discount: 0,
      total: 142,
    },
  ];
  
  export default orders;
  