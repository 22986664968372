// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `invoice-container {
  font-size: 12px;
  color: #333;
  font-family: "Times New Roman", serif !important;
  margin: 0;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/admin/parts/NewParts/body/OrderManagement/style/order.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,WAAA;EACA,gDAAA;EACA,SAAA;EACA,UAAA;AACJ","sourcesContent":["invoice-container {\n    font-size: 12px;\n    color: #333;\n    font-family: 'Times New Roman', serif !important;\n    margin: 0;\n    padding: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
