import React from "react";
function Settings() {
  return (
    <div>
     This Settings
    </div>
  );
}

export default Settings;
