// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coupon-container {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 16px;
    margin: 10px 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.coupon-container:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.coupon-icon {
    width: 50px;
    height: 50px;
    margin-right: 16px;
}

.coupon-icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.coupon-content {
    flex: 1 1;
}

.coupon-discount {
    color: #233446;
    font-size: 1.1rem;
    font-weight: bold;
    margin: 0;
}

.coupon-code {
    color: #666;
    font-size: 0.9rem;
    margin: 4px 0;
}

.coupon-name {
    /* color: #444; */
    font-size: 0.8rem;
    margin: 0;
}

.coupon-save-btn {
    background-color: #233446;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.coupon-save-btn:hover {
    background-color: #1d2a38;
}`, "",{"version":3,"sources":["webpack://./src/pages/client/coupon.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,cAAc;IACd,wCAAwC;IACxC,qDAAqD;AACzD;;AAEA;IACI,sBAAsB;IACtB,0CAA0C;AAC9C;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,SAAO;AACX;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,iBAAiB;IACjB,SAAS;AACb;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,SAAS;AACb;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,iBAAiB;IACjB,eAAe;IACf,sCAAsC;AAC1C;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".coupon-container {\n    display: flex;\n    align-items: center;\n    background-color: #ffffff;\n    border: 1px solid #e0e0e0;\n    border-radius: 8px;\n    padding: 16px;\n    margin: 10px 0;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n    transition: transform 0.2s ease, box-shadow 0.2s ease;\n}\n\n.coupon-container:hover {\n    transform: scale(1.02);\n    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);\n}\n\n.coupon-icon {\n    width: 50px;\n    height: 50px;\n    margin-right: 16px;\n}\n\n.coupon-icon img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}\n\n.coupon-content {\n    flex: 1;\n}\n\n.coupon-discount {\n    color: #233446;\n    font-size: 1.1rem;\n    font-weight: bold;\n    margin: 0;\n}\n\n.coupon-code {\n    color: #666;\n    font-size: 0.9rem;\n    margin: 4px 0;\n}\n\n.coupon-name {\n    /* color: #444; */\n    font-size: 0.8rem;\n    margin: 0;\n}\n\n.coupon-save-btn {\n    background-color: #233446;\n    color: #ffffff;\n    border: none;\n    border-radius: 4px;\n    padding: 8px 16px;\n    font-size: 0.9rem;\n    cursor: pointer;\n    transition: background-color 0.2s ease;\n}\n\n.coupon-save-btn:hover {\n    background-color: #1d2a38;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
