// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  border-radius: 20px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.card-body {
  padding: 2rem;
}

.form-control {
  border-radius: 10px;
  padding: 0.75rem;
  font-size: 1rem;
}

.btn {
  border-radius: 50px;
  padding: 0.75rem;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.btn-primary {
  background-color: #717fe0;
  border: none;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-outline-danger:hover {
  background-color: #dc3545;
  color: white;
}

.btn-outline-primary:hover {
  background-color: #717fe0;
  color: white;
}

.btn-link {
  text-decoration: none;
  font-weight: 500;
}

.btn-link:hover {
  text-decoration: underline;
}

.no-underline {
  text-decoration: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/auth/Login.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,yCAAyC;AAC3C;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".card {\n  border-radius: 20px;\n  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);\n}\n\n.card-body {\n  padding: 2rem;\n}\n\n.form-control {\n  border-radius: 10px;\n  padding: 0.75rem;\n  font-size: 1rem;\n}\n\n.btn {\n  border-radius: 50px;\n  padding: 0.75rem;\n  font-size: 1rem;\n  transition: all 0.3s ease;\n}\n\n.btn-primary {\n  background-color: #717fe0;\n  border: none;\n}\n\n.btn-primary:hover {\n  background-color: #0056b3;\n}\n\n.btn-outline-danger:hover {\n  background-color: #dc3545;\n  color: white;\n}\n\n.btn-outline-primary:hover {\n  background-color: #717fe0;\n  color: white;\n}\n\n.btn-link {\n  text-decoration: none;\n  font-weight: 500;\n}\n\n.btn-link:hover {\n  text-decoration: underline;\n}\n\n.no-underline {\n  text-decoration: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
