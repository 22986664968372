// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom file input design */
.custom-file-input {
    position: relative;
    display: inline-block;
  }
  
  .upload-label {
    background-color: #f0f0f0;
    padding: 12px 20px;
    border-radius: 8px;
    cursor: pointer;
    display: block;
    width: 200px;
    text-align: center;
    margin: 10px 0;
  }
  
  .upload-label:hover {
    background-color: #e0e0e0;
  }
  
  #image-upload {
    display: none;
  }
  
  /* Image preview container */
  .image-preview-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .image-item {
    position: relative;
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 8px;
  }
  
  .image-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .remove-image-btn {
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    padding: 5px;
    font-size: 14px;
  }
  
  .remove-image-btn:hover {
    background: rgba(0, 0, 0, 0.7);
  }
  `, "",{"version":3,"sources":["webpack://./src/components/admin/parts/NewParts/body/AdvertisementManagement/NewAdvertisement.css"],"names":[],"mappings":"AAAA,6BAA6B;AAC7B;IACI,kBAAkB;IAClB,qBAAqB;EACvB;;EAEA;IACE,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,cAAc;EAChB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,aAAa;EACf;;EAEA,4BAA4B;EAC5B;IACE,aAAa;IACb,eAAe;IACf,SAAS;EACX;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,8BAA8B;IAC9B,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,8BAA8B;EAChC","sourcesContent":["/* Custom file input design */\n.custom-file-input {\n    position: relative;\n    display: inline-block;\n  }\n  \n  .upload-label {\n    background-color: #f0f0f0;\n    padding: 12px 20px;\n    border-radius: 8px;\n    cursor: pointer;\n    display: block;\n    width: 200px;\n    text-align: center;\n    margin: 10px 0;\n  }\n  \n  .upload-label:hover {\n    background-color: #e0e0e0;\n  }\n  \n  #image-upload {\n    display: none;\n  }\n  \n  /* Image preview container */\n  .image-preview-container {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 10px;\n  }\n  \n  .image-item {\n    position: relative;\n    width: 100px;\n    height: 100px;\n    overflow: hidden;\n    border-radius: 8px;\n  }\n  \n  .image-preview {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    border-radius: 8px;\n  }\n  \n  .remove-image-btn {\n    position: absolute;\n    top: 0;\n    right: 0;\n    background: rgba(0, 0, 0, 0.5);\n    color: white;\n    border: none;\n    border-radius: 50%;\n    cursor: pointer;\n    padding: 5px;\n    font-size: 14px;\n  }\n  \n  .remove-image-btn:hover {\n    background: rgba(0, 0, 0, 0.7);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
