// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-scrollbar {
    height: 55vh;
    overflow: auto;
    overflow-x: hidden;
  }
  
  .custom-scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/assets/style/custom-scroll.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,UAAU;IACV,WAAW;EACb;;EAEA;IACE,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,sBAAsB;IACtB,mBAAmB;EACrB","sourcesContent":[".custom-scrollbar {\n    height: 55vh;\n    overflow: auto;\n    overflow-x: hidden;\n  }\n  \n  .custom-scrollbar::-webkit-scrollbar {\n    width: 8px;\n    height: 8px;\n  }\n  \n  .custom-scrollbar::-webkit-scrollbar-thumb {\n    background-color: #888;\n    border-radius: 10px;\n  }\n  \n  .custom-scrollbar::-webkit-scrollbar-thumb:hover {\n    background-color: #555;\n  }\n  \n  .custom-scrollbar::-webkit-scrollbar-track {\n    background-color: #fff;\n    border-radius: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
